
import { Component, Vue } from "vue-property-decorator";
import { getRole, getRoleName } from "@/store/admin/getters";
import { dispatchGetRole, dispatchEnableRole, dispatchDisableRole } from '@/store/admin/actions'

@Component
export default class EditRole extends Vue {
    public headers = [
    {
      text: 'ID User',
      sortable: true,
      value: 'user_id',
      align: 'left',
    },
    {
      text: 'Nom',
      sortable: true,
      value: 'full_name',
      align: 'left',
    },
    {
      text: 'Email',
      sortable: true,
      value: 'email',
      align: 'left',
    },
    {
      text: 'A le rôle',
      value: 'id',
    },
  ];

  public async onCheckboxChange(item){
    if(item.has_role) {
      dispatchEnableRole(this.$store, {role: Number(this.$route.params.id), user: item.user_id})
    } else {
      dispatchDisableRole(this.$store, {role: Number(this.$route.params.id), user: item.user_id})
    }
  }

  get users() {
    return getRole(this.$store);
  }

  get rolename() {
    return getRoleName(this.$store)(Number(this.$route.params.id));
  }

  public async mounted() {  
    await dispatchGetRole(this.$store, {id: Number(this.$route.params.id)});
  }
}
